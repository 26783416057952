@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Gotham";
    src: url("./assets/font/GothamSSm-Book_Web.woff2") format('woff2');
}
html,
body {
    overflow-x: hidden;
    font-family: 
        "Gotham",
        "Arial",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI Variable",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        sans-serif;
}